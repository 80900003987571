var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"grad"}),_c('HelpModal'),(false)?_c('Languages'):_vm._e(),_c('PokedexModal',{attrs:{"pokedex":_vm.pokedex,"win":_vm.win,"pokemon":_vm.pokemon}}),_c('img',{staticClass:"logo",attrs:{"src":require("./assets/logo.png"),"alt":"Pokemodle"}}),(_vm.pokemon.id)?_c('div',{staticClass:"scene"},[_c('img',{class:{
  pokemon: true,
  active: _vm.win,
  disappear: !_vm.intents && !_vm.pokemon.active,
},attrs:{"src":'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/' +
      _vm.pokemon.id +
      '.png',"alt":"","fetchPriority":"high","draggable":"false"}})]):_vm._e(),(_vm.intents && !_vm.pokemon.active)?_c('div',[_c('div',[(_vm.input)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],staticClass:"textInput",class:{ 'error-shake': _vm.errorShake },attrs:{"placeholder":_vm.$t('question'),"readonly":""},domProps:{"value":(_vm.input)},on:{"input":function($event){if($event.target.composing)return;_vm.input=$event.target.value}}}):_c('p',{staticClass:"textInput"},[_vm._v(_vm._s(_vm.$t('question')))]),(_vm.input &&
        _vm.input.length >= 2 &&
        _vm.pokedex.filter(
          (p) => p.name.toLowerCase().indexOf(_vm.input.toLowerCase()) == 0
        ).length > 0
        )?_c('div',{staticClass:"autocomplete"},[_c('ul',_vm._l((_vm.pokedex.filter(
            (p) => p.name.toLowerCase().indexOf(_vm.input.toLowerCase()) == 0
          )),function(pokemon,index){return _c('li',{key:index,on:{"click":function($event){$event.preventDefault();return _vm.setAndValidate(pokemon)}}},[_vm._v(" "+_vm._s(pokemon.name)+" ")])}),0)]):_vm._e()]),_c('section',{staticClass:"pokeballs"},[_c('p',[_vm._v(_vm._s(_vm.$t("attempts"))+" = "+_vm._s(_vm.intents))]),_c('div',{staticClass:"intents"},[_vm._l((_vm.intents),function(item){return _c('img',{key:item + 1000,staticClass:"pokeball",attrs:{"src":require("./assets/pokeball.png"),"alt":""}})}),_vm._l((5 - _vm.intents),function(item){return _c('img',{key:item + 2000,staticClass:"pokeball disabled",attrs:{"src":require("./assets/pokeball.png"),"alt":""}})})],2)])]):_vm._e(),_c('ResultModal',{attrs:{"pokemon":_vm.pokemon,"win":_vm.win,"intents":_vm.intents}}),(!_vm.win && _vm.intents)?_c('FixKeyboard',{on:{"setKey":(v) => _vm.setKey(v)}}):_vm._e(),_c('BuyMeACoffee')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }