var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('button',{staticClass:"help",on:{"click":() => {
    this.helped = true;
  }}},[_vm._v(" ? ")]),(_vm.helped)?_c('div',{staticClass:"popup active",on:{"click":() => {
    this.helped = false;
  }}},[_c('div',[_c('button',{staticClass:"close",on:{"click":() => {
        this.helped = false;
      }}},[_vm._v(" x ")]),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("help.title")))]),_c('p',[_vm._v(_vm._s(_vm.$t("help.description")))]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t("help.strong")))])]),_c('hr',{staticClass:"line"}),_c('p',{staticClass:"small"},[_vm._v(_vm._s(_vm.$t("help.version"))+" "+_vm._s(_vm.version))]),_c('GitHub'),_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("help.legal"))+" ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }