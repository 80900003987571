var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.win)?_c('div',{staticClass:"msg"},[_c('div',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("congratulations")))]),_c('p',[_vm._v(_vm._s(_vm.$t("catch")))]),_c('div',{staticClass:"share"},[_c('a',{attrs:{"href":`https://twitter.com/intent/tweet?url=https%3A%2F%2Fpokemodle.salteadorneo.dev%2F&text=${encodeURIComponent(
          _vm.shareText
        )}`,"target":"_blank"},on:{"click":function($event){return _vm.setEvent('twitter')}}},[_c('TwitterIcon')],1),_c('a',{attrs:{"href":`https://api.whatsapp.com/send?text=${encodeURIComponent(
          _vm.shareText + ' https://pokemodle.salteadorneo.dev/'
        )}`,"target":"_blank"},on:{"click":function($event){return _vm.setEvent('whatsapp')}}},[_c('WhatsappIcon')],1),_c('a',{attrs:{"href":`https://telegram.me/share/url?url=https%3A%2F%2Fpokemodle.salteadorneo.dev%2F&text=${encodeURIComponent(
          _vm.shareText
        )}`,"target":"_blank"},on:{"click":function($event){return _vm.setEvent('telegram')}}},[_c('TelegramIcon')],1),_c('button',{staticClass:"btn rounded",on:{"click":_vm.clipboard}},[_c('CopyIcon')],1)]),_c('p',[_vm._v(_vm._s(_vm.$t("comeback")))])])]):_vm._e(),(!_vm.win && !_vm.intents)?_c('div',{staticClass:"msg"},[_c('div',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("flee")))]),_c('p',[_vm._v(_vm._s(_vm.$t("comeback")))])])]):_vm._e(),(_vm.win || (!_vm.win && !_vm.intents))?_c('BuyMeACoffee',{attrs:{"center":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }